@mixin adaptiv-property($cssProperty, $pcSize, $mobSize, $isFreeMode) {
  $addSize: $pcSize - $mobSize;
  @if $isFreeMode==1 {
    #{$cssProperty}: calc(
      #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{1440 - 320})
    );
  } @else {
    @media (max-width: 1440px) {
      #{$cssProperty}: calc(
        #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{1440 - 320})
      );
    }
    @media (min-width: 1441px) {
      #{$cssProperty}: #{$pcSize + px};
    }
  }
}

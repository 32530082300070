.section {
    padding: 0 70px 60px;

    @media (min-width:90.01em) {
        padding: 0 70px 40px;
    }

    @media (max-width:64em) {
        padding: 40px;
    }

    @media (max-width:48em) {
        min-height: auto;
        padding: 24px 40px;
    }

    @media (max-width:35em) {
        padding: 24px 20px;
    }
}

.container {
    max-width: 1060px;
    margin: 0 auto;

    @media (min-width:90.01em) {
        max-width: 75%;
    }
}

.body {
    //display: grid;
    //grid-template-columns: 0.7fr 0.3fr;
    //gap: 87px;
    //align-items: center;

    @media (max-width: 48em){
        grid-template-columns: 1fr;
        place-items: center;
        gap: 24px;
    }
}

.text {
    margin: 0 auto;
    display: grid;
    gap: 48px;

    @media (max-width: 48em){
        max-width: 100%;
    }

    p:nth-child(2) {
        display: block;

        @media (max-width: 48em){
            display: none;
        }
    }
}

.text p,
.mobile {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.33;
    color: #141416;

    @media (max-width: 48em) {
            font-weight: 400;
            font-size: 0.875rem;
        }
}

.mobile {
    display: none;

    @media (max-width: 48em){
        display: block;
    }
}

.image {
    display: flex;
    justify-content: center;

    svg {
        display: flex;
        max-width: 100%;

        height: auto;

        @media (min-width:90.01em) {
            width:100%;
        }

        @media (max-width: 48em) {
            width: clamp(152px, 45vw, 300px);
        }
    }


}
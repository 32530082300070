.section {
  padding: 120px 75px 60px 75px;
  @media (max-width: 64em) {
    padding: 120px 40px;
  }

  @media (max-width: 48em) {
    min-height: auto;
    padding: 150px 40px 24px;
  }

  @media (max-width: 35em) {
    padding: 150px 20px 24px;
  }
}

.body {
  max-height: 660px;
  padding: 50px 80px 55px 80px;
  background: conic-gradient(from 206.21deg at 64.89% 53.96%, #160265 0deg, #FF7B01 360deg);
  border-radius: 56px;
  display: grid;
  //grid-template-columns: calc(30% - 16px) calc(45% - 16px) calc(25% - 16px);
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: 40px;
  @media (max-width: 1280px) {
    padding: 50px;
  }
  @media (max-width: 1024px) {
    padding: 40px;
  }
  @media (max-width: 860px) {
    grid-template-columns: 1fr;
    max-height: initial;
    position: relative;
  }
  @media (max-width: 768px) {
    padding: 40px 20px;
  }

}

.slide-left {
  --vw: calc(((100vw - 150px) * 30) / 10000);
  display: flex;
  flex-direction: column;
  animation: none;
  gap: 32px;
  height: 100%;
  justify-content: center;
  position: relative;
  @media (max-width: 860px) {
    position: initial;
  }
  .content {
    @media (max-width: 860px) {
      display: grid;
      place-items: center;
      position: initial;
    }

    .title {
      font-size: 48px;
      line-height: 111.22%;
      color: #FFFFFF;
      font-family: "Proxima Nova", sans-serif;
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      @media (max-width: 1366px) {
        font-size: 46px;
      }
      @media (max-width: 1280px) {
        font-size: 42px;
      }
      @media (min-width: 90.01em) {
        --fs: calc(12.4031 * var(--vw));
        font-size: var(--fs);
        margin-bottom: 0.5em;
      }

      @media (max-width: 64em) {
        margin-bottom: 20px;
        font-size: 36px;
      }

      @media (max-width: 860px) {
        font-size: 2.5rem;
        text-align: center;
        width: 18ch;
      }

      @media (max-width: 35em) {
        font-size: 1.8rem;
      }

      @media (max-width: 25em) {
        font-size: 1.5rem;
      }
      @media (max-width: 320px) {
        font-size: 1.3rem;
      }
    }

    .text {
      //width: 99%;
      font-weight: 400;
      font-size: 16px;
      line-height: 170%;
      color: rgba(255, 255, 255, 0.8);
      font-family: 'Rubik', sans-serif;
      margin-bottom: 25px;
      @media (max-width: 1980px) {
        max-width: 538.62px;
      }
      @media (max-width: 1440px) {
        max-width: 100%;
      }
      @media (min-width: 90.01em) {
        //--fs: calc(3.615 * var(--vw));
        //font-size: var(--fs);
        margin-bottom: 1.5em;
      }
      @media (max-width: 64em) {
        font-size: 14px;
      }
      @media (max-width: 860px) {
        text-align: center;
        margin-bottom: 38px;
      }
      @media (max-width: 320px) {
        font-size: 12px;
       margin-bottom: 28px;
      }
    }
  }
  .iso {
    display: flex;
    height: 48px;
    width: 68px;
    position: absolute;
    bottom: 5%;
    left: 0;
    @media (max-width: 1240px) {
      position: initial;
      margin: 35px 0 0 0;
    }
    @media (max-width: 860px) {
      position: absolute;
      left: 5%;
    }
    @media (max-width: 620px) {
      height: 32px;
      width: 48px;
    }
    @media (max-width: 425px) {
      left: 7%;
    }
    //@media (max-width: 48em) {
    //  display: none;
    //}
  }

  .row{
    display: flex;
    align-items: center;
    gap: 44px;
    width: fit-content;
    @media (max-width: 35em) {
      flex-direction: column;
      gap: 20px;
    }
    .button{
      color: #FFFFFF;
      background: transparent;
      box-shadow: inset 0 0 0 2px #FFFFFF;
    }
    .download{
      gap: 5px;
      font-family: "Proxima Nova", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: clamp(1rem, 3.335vw, 1rem);
      display: flex;
      align-items: center;
      color: #FFFFFF;
      cursor: pointer;
      letter-spacing: 2px;
      text-transform: uppercase;
      img{
        width: 20px;
        height: 20px;
      }
    }
  }
}

.slide-center {
  display: grid;
  place-items: center;

  @media (max-width: 48em) {
    grid-template-columns: 88px 1fr 88px;
  }

  @media (max-width: 35em) {
    grid-template-columns: 50px 1fr 50px;
  }

  @media (max-width: 25em) {
    grid-template-columns: 36px 1fr 36px;
  }

  &__epic {
    @media (max-width: 48em) {
      grid-template-columns: 1fr;
    }
  }

  svg {
    max-width: 100%;
    display: flex;
    height: auto;
    width: 100%;

    @media (max-width: 35em) {
      max-width: 90%;
    }

    @media (max-width: 25em) {
      max-width: 95%;
    }
  }

  .iso {
    display: none;
    max-width: 100%;

    @media (max-width: 48em) {
      display: flex;
      place-self: end;
      margin-bottom: 20px;
    }
  }
}

.slide-right {
  display: grid;
  height: 100%;
  justify-items: end;
  align-items: end;

  @media (max-width: 48em) {
    //display: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 100%;
    @media (max-width: 1280px) {
      height: 90%;
      width: 90%;
    }
    @media (max-width: 48em) {
      height: 100%;
      width: 70%;
    }
  }
}

.section {
  display: grid;
  gap: 40px;

  @media (max-width: 48em) {
    gap: 25px;
  }
}

.title {
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.58;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #140065;
  text-align: center;

  @media (min-width:90.01em) {
   font-size: 2.22vw;
}

  @media (max-width: 35em) {
   font-size: 1.5rem;
  }
}

.image {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  filter: grayscale(0.5);
  opacity: 0.7;
  transition: 0.5s;
  width: 100%;
  @media (max-width: 600px) {
   display: none;
  }
  @media (max-width: 35em) {
    gap: 20px;
  }
  svg{
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
}
.image_mobile{
  display: none;
  @media (max-width: 600px) {
    display: flex;
    svg{
      width: 90%;
      margin: 0 auto;
    }
  }
}
@media (min-height: 35em) {

    .circles,
    .robot-big-full,
    //.slide-left,
    .robot-small-full,
    .robot-middle,
    .buttons {
        opacity: 0;
        visibility: hidden;
        will-change: transform, opacity, visibility;
    }

    .robot-big {
        display: flex;
    }
}
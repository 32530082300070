@import "../../scss/mixins";

.video {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    left: 0;
    top: 0;
    display: block;
    outline: none;
    border: none;
  }
}

.slutions_active .light-bulb {
  animation: lightBulbIn 0.5s ease 1s forwards;
}
.cases_active {
  animation: robotCasesIn 0.5s ease-out 1s forwards;
}

.cases_active .cases_logos {
  animation: logosIn 0.5s ease-out 1.5s forwards;
}

.cases_active .left-hand-cases-robot {
  animation: robotHandUpDown 0.2s ease-out 1.8s forwards;
}

.cases_active .robot_box_cases {
  animation: boxRobotDownUp 0.2s ease-out 1.8s forwards;
}

.cases_active .cases_robot_head {
  animation: robotHeadSwave 0.4s ease-out 1s forwards;
}

.cases_active .box_decorate_robot_cases {
  animation: boxRobotDownUp 0.2s ease-out 1.8s forwards;
}

.services_active .services_logo {
  animation: logosServiceRobotIn 0.5s ease-out 1s forwards;
}

.services_active .services_robot_left_hand {
  animation: robotServiceLeftHand 0.2s ease-out 1.4s forwards;
}

.services_active .services_robot_right_hand {
  animation: robotServiceRightHand 0.2s ease-out 1.4s forwards;
}

@keyframes lightBulbIn {
  0% {
    transform: translateY(7%) scale(0);
    opacity: 0;
  }
  80% {
    transform: translateY(-1%) scale(1.05);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes robotCasesIn {
  0% {
    transform: translateX(100%);
  }
  80% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes logosIn {
  0% {
    transform: translateY(-400%);
  }
  80% {
    transform: translateY(15%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes robotHandUpDown {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes boxRobotDownUp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes robotHeadSwave {
  0% {
    transform: rotate(26deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes logosServiceRobotIn {
  0% {
    transform: translateY(-600%) scale(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-200%) scale(1);
    opacity: 1;
  }
  90% {
    transform: translateY(10%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

@keyframes robotServiceLeftHand {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes robotServiceRightHand {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

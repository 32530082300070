@import "../../../scss/mixins";

.ai_expertise{
  margin: 0 auto;
  max-width: 1200px;
  background: #FFFFFF;
  border: 2px solid #E2E9FF;
  border-radius: 32px;
  padding: 48px;
  @media (min-width: 2000px) {
    max-width: 60%;
  }
  @media (max-width: 1280px) {
    max-width: calc(100% - 80px);
  }
  @media (max-width: 768px) {
    max-width: calc(100% - 40px);
  }
  @media (max-width: 620px) {
    padding: 28px;
  }
  @media (max-width: 390px) {
    padding: 20px;
  }
  .title{
    font-weight: 700;
    color: #000000;
    margin: 0 0 24px 0;
    @include adaptiv-property("font-size", 32, 26, 1);
  }
  .expertise_grid{
    margin: 0 0 25px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    width: 100%;
    gap: 24px;
    @media (max-width: 980px) {
      grid-template-columns: repeat(1, 1fr);
    }
    li{
      flex-direction: column;
      gap: 24px;
      display: flex;
      @media (max-width: 620px) {
        gap: 5px;
      }
      svg{
        @media (max-width: 620px) {
        margin: 0 0 10px 0;
          width: 80px;
          height: 80px;
        }
      }
      h4{
        @include adaptiv-property("font-size", 24, 22, 1);
        font-family: 'Biotif', sans-serif;
        font-style: normal;
        font-weight: 700;
        color: #000000;
      }
      p{
        @include adaptiv-property("font-size", 16, 14, 1);
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        line-height: 170%;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .expertise_action{
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
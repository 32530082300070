.button {
    border: none;
    outline: none;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.625;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #140065;
    cursor: pointer;
    width: 192px;
    height: 46px;
    background-color: #fff;
    box-shadow: inset 0 0 0 2px #140065;
    border-radius: 2px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Proxima Nova', sans-serif;
    overflow: hidden;

    &::after {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.625;
        font-family: 'Proxima Nova', sans-serif;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        content: attr(data-content);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #EE7208;
        z-index: 10;
        border-radius: 2px;
        clip-path: polygon(0 0, 8px 0, 8px 100%, 0% 100%);
        transition: clip-path 0.5s;
    }


    &_purple{
        &::after {
            background-color: #842EAD;
        }
    }

    &:hover {
        &::after {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
        }
    }

    &:disabled {
        opacity: 0.7;
        pointer-events: none;
    }
}
@import "../../../scss/mixins";

.expertise {
  &__card {
    max-width: 1200px;
    width: 100%;
    box-shadow: 0 0.4em 3.5em rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    overflow: hidden;
    background: right top  no-repeat
      url(../../../assets/img/Home/expertise/bg_top.png);
    @include adaptiv-property("margin-bottom", 30, 15, 1);
    @include adaptiv-property("border-radius", 15, 10, 1);
    @media (min-width: 2000px) {
      max-width: 60%;
    }
    @media (max-width: 1280px) {
      max-width: calc(100% - 80px);
    }
    @media (max-width: 768px) {
      max-width: calc(100% - 40px);
    }
    &:nth-child(2){
      background: none;
    }
    &__rest{
      background: right top / contain no-repeat
      url(../../../assets/img/Home/expertise/bg_restaurants.svg);
      @media (max-width: 1920px) {
        max-height: 610px;
        background-size: 45%;
        background-position: right bottom;
      }
      @media (max-width: 1680px) {
        max-height: 500px;
      }
      @media (max-width: 1440px) {
        max-height: initial;
      }

      @media (max-width: 1024px) {
        background-size: auto;
      }

      &:nth-child(1){
        .background {
          @include adaptiv-property("bottom", -450, -30, 1);
          left: 0;
        }
        .expertise__image,
        .expertise__image_mobile {
          position: relative;

          @media (min-width: 998px) and (orientation: landscape) {
            //padding: 15px 0!important;
            justify-content: flex-end;

          }
          @media (min-width: 998px) and (orientation: landscape) {

          }
          @media (max-width: 998px), (orientation: portrait) {

          }
        }
      }

      &__text {
        flex: 1 1 auto;
        @include adaptiv-property("padding-top", 57, 30, 1);
        @media (min-width: 999px) {
          @include adaptiv-property("padding-left", 59, 20, 1);
        }

        @media (max-width: 998px), (orientation: portrait) {
          padding-left: 0;
          width: 100% !important;
          margin: 0 auto;
          text-align: center;
          flex: none;
        }
      }
      &__description {
        font-family: 'Rubik', sans-serif;
        width: 90%;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        margin: 0 auto;
        font-size: 16px;
        line-height: 170%;
        color: rgba(0, 0, 0, 0.6);
        span{
          font-weight: bold;
          color: #525151;
        }
        //@include adaptiv-property("font-size", 44, 14, 1);

        @include adaptiv-property("margin-bottom", 56, 36, 1);
        @media (min-width: 998px) and (orientation: landscape) {
         margin: 0;
          @include adaptiv-property("margin-bottom", 24, 14, 1);
          @include adaptiv-property("padding-right", 10, 5, 1);
        }

        @media (min-width: 999px) {
          //@include adaptiv-property("font-size", 14, 2, 1);
          line-height: 21px;
        }
        @media (max-width: 620px) {
          font-size: 14px;
        }
      }
    }
    .background {
      position: absolute;
      width: 100%;
      display: none;
      z-index: -1;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    @media (max-width: 998px) {
      border-radius: 15px;
    }
    @media (max-width: 998px), (orientation: portrait) {
      width: 90%;
      position: relative;
      background: none;
      .background {
        display: block;
      }
    }
    @media (max-width: 998px) {
      margin-bottom: 30px;
    }

    &:nth-child(1) {
      .background {
        width: 105%;
        left: 0;
        @include adaptiv-property("bottom", -500, -50, 1);
      }

      .expertise__image,
      .expertise__image_mobile {
        position: relative;
        .cases_logos {
          position: absolute;
          width: 24%;
          z-index: -1;
          top: 33%;
          left: 9.5%;
          transform: translateY(-400%);
        }

        @media (min-width: 998px) and (orientation: landscape) {
          //@include adaptiv-property("padding-right", 80, 60, 1);
          svg {
            height: 100%;
            width: 100%;
          }
        }
        @media (max-width: 998px), (orientation: portrait) {
          svg {
            width: 50%;
            height: auto;
          }
          .cases_logos {
            @include adaptiv-property("width", 130, 40, 1);
            top: 30%;
            left: 31.5%;
            transform: translateY(-1000%);
          }
        }
      }
    }
    &:nth-child(2) {
      .background {
        @include adaptiv-property("bottom", -450, -30, 1);
        left: 0;
      }
      .expertise__image,
      .expertise__image_mobile {
        position: relative;
        .services_logo {
          position: absolute;
          width: 7%;
          height: 9%;
          top: 30%;
          &:nth-child(2) {
            left: -3%;
          }
          &:nth-child(3) {
            right: 6%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        @media (min-width: 998px) and (orientation: landscape) {
          //@include adaptiv-property("padding-right", 20, 10, 1);
          svg {
            width: 100%;
            height: 100%;
          }
        }
        @media (max-width: 998px), (orientation: portrait) {
          svg {
            width: 80%;
            height: auto;
          }
          .services_logo {
            transform: translateY(-1200%) scale(0);
            opacity: 0;
            width: 10%;
            height: 15%;
            top: 13%;
            &:nth-child(2) {
              left: 6%;
            }
            &:nth-child(3) {
              right: 7%;
            }
          }
        }
      }
    }
  }

  &__text {
    flex: 1 1 auto;
    @include adaptiv-property("padding-top", 57, 30, 1);
    @media (min-width: 999px) {
      @include adaptiv-property("padding-left", 59, 20, 1);
    }
    @media (max-width: 998px), (orientation: portrait) {
      padding-left: 0;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      flex: none;
    }
  }
  &__title {
    @include adaptiv-property("margin-bottom", 24, 14, 1);
    @include adaptiv-property("letter-spacing", 2, 1, 1);
    //@include adaptiv-property("font-size", 54, 16, 1);
    line-height: 1.6;
    text-transform: uppercase;
    font-family: "Biotif", sans-serif;
    @media (min-width: 1920px) {
      font-size: 22px;
    }
    @media (min-width: 1440px) {
      @include adaptiv-property("font-size", 24, 16, 1);
    }
    @media (min-width: 999px) {
      @include adaptiv-property("font-size", 22, 6, 1);
    }
    @media (min-width: 998px) and (orientation: portrait) {
      @include adaptiv-property("font-size", 54, 14, 1);
    }
    @media (max-width: 620px) {
      font-size: 20px;
    }
    @media (max-width: 390px) {
      font-size: 18px;
    }
  }

  &__description {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 27px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    span{
      font-weight: bold;
      color: #525151;
    }
    //@include adaptiv-property("font-size", 44, 14, 1);

    @include adaptiv-property("margin-bottom", 56, 36, 1);
    @media (min-width: 998px) and (orientation: landscape) {
      @include adaptiv-property("margin-bottom", 24, 14, 1);
      max-width: 87%;
    }
    @media (min-width: 999px) {
      //@include adaptiv-property("font-size", 14, 2, 1);
      line-height: 21px;
    }
    @media (min-width: 998px) and (orientation: portrait) {
      @include adaptiv-property("font-size", 44, 14, 1);
    }
    @media (max-width: 620px) {
      font-size: 14px;
    }
  }
  &__button {
    @include adaptiv-property("margin-bottom", 60, 30, 1);
    @media (max-width: 998px), (orientation: portrait) {
      display: flex;
      justify-content: center;
      @include adaptiv-property("margin-top", 40, 26, 1);
      @include adaptiv-property("margin-bottom", 40, 25, 1);
    }
  }
  &__image_mobile {
    display: none;
    svg {
      width: 100%;
    }
    @media (max-width: 998px), (orientation: portrait) {
      display: block;
      max-width: 60%;
      margin: 0 auto;
    }
    @media (max-width: 620px) {
      max-width: 80%;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
  img{
    width: 100%;
    height: 100%;
  }
    @media (max-width: 998px), (orientation: portrait) {
      display: none;
    }
  }
  &__image_rest{
    flex: 0 0 50%;
    padding: 0!important;
    display: flex;
    align-items: flex-end;
    @media (max-width: 1920px){
      img{
        height: 75%;
      }
    }
    @media (max-width: 1440px){
      img{
        height: 100%;
      }
    }
  }
}

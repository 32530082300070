.popup {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition-duration: 366ms;
}

.popup .close-block {
  position: absolute;
  top: 0;
  right: 0;
}

.content_block {
  background: #fff;
  max-width: 1024px;
  margin: 90px 0px;
  height: auto;
  padding: 20px;
  width: 90%;
  overflow: auto;
}

.content_block p {
  font-family: "Proxima Nova", serif;
  margin: 10px 0;
}

.content_block h1,
.content_block h2,
.content_block h3,
.content_block h4 {
  margin-top: 22px;
  margin-bottom: 11px;
}

.button-close {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  margin: 40px;
  position: relative;
  width: 50px;
  fill: #fff;
}

@media (max-width: 768px) {
  .button-close {
    margin: 0;
    /* border: none; */
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
  }
}
